import * as React from "react"
import { graphql } from "gatsby"

import PageHeader from "../../page-header"
import Footer from "../../footer"
import ArticleHeader from "../../article-header"
import ArticleBox from "../../article-box"

import "../../../scss/site.scss"
//import StickyCta from "../../sticky-cta"

export const query = graphql`
  fragment BlockFields on WpBlock {
    name
    originalContent
    ... on WpCoreGroupBlock {
      attributes {
        ... on WpCoreGroupBlockAttributes {
          anchor
          className
        }
      }
    }
    ... on WpDemokratipagarInfoboxBlock {
      attributes {
        type
        size
      }
    }
    ... on WpCoreParagraphBlock {
      attributes {
        ... on WpCoreParagraphBlockAttributes {
          content
        }
      }
    }
    ... on WpCoreHeadingBlock {
      attributes {
        ... on WpCoreHeadingBlockAttributes {
          className
          level
          content
        }
      }
    }
  }

  fragment TutorialFields on WpPage {
    title
    blocks {
      ...BlockFields
      innerBlocks {
        ...BlockFields
        innerBlocks {
          ...BlockFields
          innerBlocks {
            ...BlockFields
          }
        }
      }
    }
  }
`

const sortBlocks = blocks => {
  const preambleBlocks = []
  const contentBlocks = []
  const columnBlocks = []

  const isPreamble = block =>
    block.name === "core/group" && block.attributes.className === "preamble"
  const isColumns = block => block.name === "core/columns"

  blocks.forEach(block => {
    switch (true) {
      case isPreamble(block):
        preambleBlocks.push(...block.innerBlocks)
        break
      case isColumns(block):
        if (block.innerBlocks[0]) {
          contentBlocks.push(...block.innerBlocks[0].innerBlocks)
        }
        if (block.innerBlocks[1]) {
          columnBlocks.push(...block.innerBlocks[1].innerBlocks)
        }
        break
      default:
        contentBlocks.push(...block.innerBlocks)
    }
  })

  return [preambleBlocks, contentBlocks, columnBlocks]
}

const renderBlocks = (blocks, keyPrefix, level = 0) => {
  level++
  return blocks.map((block, index) => {
    const innerBlocks = block.innerBlocks
      ? renderBlocks(block.innerBlocks, keyPrefix, level)
      : null
    const key = `${keyPrefix}-${level}-${index}`
    switch (block.name) {
      case "core/paragraph":
        return (
          <p
            key={key}
            dangerouslySetInnerHTML={{ __html: block.attributes.content }}
          />
        )
      case "core/heading":
        return renderHeading(block, key)
      case "core/list":
        return (
          <div
            key={key}
            className="list-wrapper"
            dangerouslySetInnerHTML={{ __html: block.originalContent }}
          />
        )
      case "demokratipagar/infobox":
        return renderInfobox(block, innerBlocks, key)
      default:
        console.warn("Unknown block type %s", block.name)
        return <div key={key}>{innerBlocks}</div>
    }
  })
}

const renderHeading = (block, key) => {
  switch (block.attributes.level) {
    case 3:
      return (
        <h3
          key={key}
          className={block.attributes.className}
          dangerouslySetInnerHTML={{ __html: block.attributes.content }}
        />
      )
    case 4:
      return (
        <h4
          key={key}
          className={block.attributes.className}
          dangerouslySetInnerHTML={{ __html: block.attributes.content }}
        />
      )
    default:
      return (
        <h2
          key={key}
          className={block.attributes.className}
          dangerouslySetInnerHTML={{ __html: block.attributes.content }}
        />
      )
  }
}

const renderInfobox = (block, innerBlocks, key) => {
  const attributes = block.attributes || {}
  let title = ""
  if (block.innerBlocks[0] && block.innerBlocks[0].name === "core/heading") {
    title = block.innerBlocks[0].attributes.content
    innerBlocks.shift()
  }
  let modifier = ""
  switch (attributes.type) {
    case "outline_solid":
      modifier = "c-article-box--stroke"
      break
    case "outline_dashed":
      modifier = "c-article-box--dashed"
      break
    case "fill":
      modifier = "c-article-box--fill"
      break
    default:
      console.warn("Unknown infobox type %s", attributes.type)
  }

  switch (attributes.size) {
    case "large":
      modifier += " c-article-box--large"
      break
    case "small":
      modifier += " c-article-box--small"
      break
  }
  return (
    <ArticleBox key={key} modifier={modifier} title={title}>
      {innerBlocks}
    </ArticleBox>
  )
}

const TutorialPage = props => {
  const pageData = props.data.pageData || {}
  const title = pageData.title || "Handledning"
  const blocks = pageData.blocks || []
  const [preambleBlocks, contentBlocks, columnBlocks] = sortBlocks(blocks)

  return (
    <div className="c-body">
      <PageHeader></PageHeader>
      <div role="main" id="main" className="o-main c-main-background">
        <article>
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-lg-9">
                <ArticleHeader title={title}>
                  {renderBlocks(preambleBlocks, "preamble")}
                </ArticleHeader>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-lg-7 c-article-content">
                {renderBlocks(contentBlocks, "content")}
              </div>
              <div className="col-lg-5">
                {renderBlocks(columnBlocks, "column")}
              </div>
            </div>
          </div>
        </article>
      </div>
      <Footer />
      {/*<StickyCta />*/}
    </div>
  )
}

export default TutorialPage
