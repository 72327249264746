import * as React from "react"

const ArticleBox = props => {
  return (
    <aside
      className={
        props.modifier ? "c-article-box " + props.modifier : "c-article-box"
      }
    >
      <div className="c-article-box__content">
        <h2 className="c-article-box__title">{props.title}</h2>
        {props.children}
      </div>
    </aside>
  )
}

export default ArticleBox
